import { systemFontScale } from '@kcd/app-interface';
import insertStyleRule from '../insertStyleRule';

const LOCAL_FONT_SCALE = 1.0; // 로컬에서 개발할 땐 이 값을 변경합니다. 모바일에서만 적용됩니다.

interface CXParams {
  on1_0x?: string; // 1.0이상 ~ 1.3미만
  on1_3x?: string; // 1.3이상 ~ 1.5미만
  on1_5x?: string; // 1.5이상 ~ 1.7미만
  on1_7x?: string; // 1.7이상
}

export type CXParamsKey = keyof CXParams;

// https://koreacreditdata.atlassian.net/wiki/spaces/ENG/pages/3578462416#to-FE
class FontScale {
  private value = 1;

  init(value: number) {
    this.value = value;
  }

  private get = () => this.value;
  private is1_3x = () => this.get() >= 1.3;
  private is1_5x = () => this.get() >= 1.5;
  private is1_7x = () => this.get() >= 1.7;

  private createPair = (params: CXParams) => {
    const { on1_0x = '', on1_3x = '', on1_5x = '', on1_7x = '' } = params;

    const classNamePair = [
      [on1_0x, true] as const,
      [on1_3x, this.is1_3x()] as const,
      [on1_5x, this.is1_5x()] as const,
      [on1_7x, this.is1_7x()] as const,
    ];
    return classNamePair;
  };

  private filter = (params: CXParams): string => {
    const pair = this.createPair(params);
    const targetClassName = pair
      .filter((item) => item[1])
      .flatMap((item) => item[0])
      .join(' ');

    return targetClassName;
  };

  private findLast = (params: CXParams): string => {
    const pair = this.createPair(params);
    const targetClassName = pair.filter((item) => item[1]).pop() || [''];

    return targetClassName[0];
  };

  private dedupe = (className: string): string => {
    const getKey = (item: string) => item.slice(0, item.lastIndexOf('-')); // space-y-4 에서 space-y 추출
    const map = new Map(
      className.split(' ').map((item) => [getKey(item), item])
    );

    const dedupedClassName = [...map.values()].join(' ');
    return dedupedClassName;
  };

  /**
   * fontScale에 따라 className을 분기처리하기 위한 함수입니다.
   * 적절한 레이아웃을 적용하기 위한 기준 fontScale이 UI마다 다르기 때문에 필요합니다.
   * (e.g. 어떤 UI는 1.5배에서 세로쌓기 적용, 어떤 UI는 1.7배에서 세로쌓기 적용)
   *
   * tailwindcss 를 사용할 경우, 중복된 속성은 마지막 값이 적용됩니다.
   * (e.g. on1_3x: 'mb-4', on1_5x: 'mb-8' -> 1.3배에서는 mb-4만, 1.5배에서는 mb-8만 적용)
   * @example
   * fontScale.cx({
   *   on1_0x: 'ml-12',
   *   on1_3x: 'space-y-4',
   *   on1_5x: 'w-full space-y-8',
   * });
   * 1.0이상 -> ml-12 적용
   * 1.3이상 -> ml-12 space-y-4 적용
   * 1.5이상 -> ml-12 w-full space-y-8 적용
   */
  cx(classNames: CXParams) {
    const className = this.filter(classNames);
    return this.dedupe(className);
  }

  /**
   * 현재 해당하는 배율의 className만 리턴합니다.
   * key값이 일치하지 않지만 배율에 따라 다른 스타일을 주어야 할 경우 사용합니다.
   * (e.g. { on1_0x: 'hidden', on1_5x: 'block' })
   */
  cxStrict(classNames: CXParams) {
    return this.findLast(classNames);
  }
}

export const fontScale = new FontScale();

export function initializeFontScale() {
  const value =
    process.env.NODE_ENV === 'development'
      ? LOCAL_FONT_SCALE
      : systemFontScale();
  fontScale.init(value);

  insertStyleRule(
    () => `
  body {
    text-size-adjust: ${value * 100}%;
    -webkit-text-size-adjust: ${value * 100}%;
  }`
  );
}

export default fontScale;
