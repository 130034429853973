let styleEl: HTMLStyleElement | undefined;

/**
 * css 규칙을 추가하는 함수입니다.
 * 성능을 위해 inline style 대신 cssom을 사용합니다.
 */
export default function insertStyleRule(param: string | (() => string)) {
  if (!param) return;
  styleEl = styleEl || document.createElement('style');
  document.head.appendChild(styleEl);

  const styleSheet = styleEl.sheet;

  const rules = typeof param === 'string' ? param : param();
  styleSheet?.insertRule(rules);
}
