import React from 'react';

import styles from 'src/styles/constants';
import Box, { BoxProps } from 'src/components/commons/Box/Box';

export const PageContainer: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box
    {...props}
    css={{
      height: '1px',
      minHeight: `calc(100vh - ${styles.navigation.header.height}px)`,
    }}
  >
    {children}
  </Box>
);
