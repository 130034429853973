export class BirchClient {
  /**
   * currentUser 받기
   *
   * a. localStorage 에 currentUser 가 있으면 넘겨주고
   * b. 없으면 api call 결과가 ok 일 때 currentUser 를 localStorage 에 저장하고 돌려줌
   *
   * @returns
   */
  async getCurrentUser(): Promise<any> {
    const currentUser = window.localStorage.getItem('currentUser');

    if (currentUser !== null && currentUser !== undefined) {
      return JSON.parse(currentUser);
    }

    // TODO: token 이 nullish 일 때 어떻게 해야 하는가?
    const token = this.getToken();
    if (token === '') {
      return;
    }

    const response = await global.fetch(
      `${global.__REDWOOD__API_PROXY_PATH}/graphql`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'auth-provider': 'custom',
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query:
            'query __REDWOOD__AUTH_GET_CURRENT_USER { redwood { currentUser } }',
        }),
      }
    );

    if (response.ok) {
      const { data } = await response.json();
      const currentUser = data?.redwood?.currentUser;
      window.localStorage.setItem('currentUser', JSON.stringify(currentUser));

      return currentUser;
    } else {
      window.localStorage.removeItem('currentUser');
      throw new Error(
        `Could not fetch current user: ${response.statusText} (${response.status})`
      );
    }
  }

  async updateAccountMeta(meta) {
    // TODO: 두 개 체크해줘서 하나라도 없는 경우는 null 리턴해주게
    const token = this.getToken();
    const currentUser = await this.getCurrentUser();

    const response = await global.fetch(
      `${global.__REDWOOD__API_PROXY_PATH}/graphql`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'auth-provider': 'custom',
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query:
            'mutation UpdateAccount($id: ID!, $input: UpdateAccountInput!) { updateAccount(id: $id, input: $input) { id meta } }',
          variables: {
            id: currentUser.account.id,
            input: { meta },
          },
        }),
      }
    );

    if (response.ok) {
      const { data } = await response.json();
      const updatedAccount = data?.updateAccount;
      const prevCurrentUser = JSON.parse(
        window.localStorage.getItem('currentUser') || '{}'
      );
      const currentUser = { ...prevCurrentUser, account: updatedAccount };
      window.localStorage.setItem('currentUser', JSON.stringify(currentUser));

      return currentUser;
    } else {
      window.localStorage.removeItem('currentUser');
      throw new Error(
        `Could not fetch current user: ${response.statusText} (${response.status})`
      );
    }
  }

  getToken() {
    // TODO: Always get a fresh token, rather than use the one in state
    const token = window.localStorage.getItem('token') || '';

    return token;
  }
}

export const client = new BirchClient();
