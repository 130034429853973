import React from 'react';
import styled from '@emotion/styled';
import { compose, flexbox } from 'styled-system';

import Box from '../Box/Box';
import type { BoxKnownProps } from '../Box/Box';
import type { FlexboxProps } from 'styled-system';

type FlexKnownProps = BoxKnownProps & FlexboxProps;
export type FlexProps = FlexKnownProps &
  Omit<React.HTMLProps<HTMLDivElement>, keyof FlexKnownProps>;

const Flex: React.FC<FlexProps> = styled(Box)<FlexProps>(
  {
    display: 'flex',
  },
  compose(flexbox)
);

export const FlexColumn = (props) => <Flex flexDirection="column" {...props} />;
export const FlexRow = (props) => <Flex flexDirection="row" {...props} />;

export default Flex;
