import ChevronRight from '@kcd/feather/dist/icons/chevron-right.svg';

const icons = {
  ChevronRight,
} as const;

type IconNames = keyof typeof icons;

// FIXME: mutually exclusive 'svg' and 'iconName'
type IconProps = React.SVGProps<SVGSVGElement> & {
  svg?: React.FC<React.SVGProps<SVGSVGElement>>;
  iconName?: IconNames;
  size?: string | number;
  color?: string;
};

const Icon: React.FC<IconProps> = (props) => {
  // svg 의 기본 사이즈는 24, color 는 currentColor 로
  // 원래는 svg 에 있었으나 feather 로 가면서 사라져서 여기에 처리
  const { svg, iconName, size = 24, color = 'currentColor', ...rest } = props;
  const Svg = svg || icons[iconName ?? 'ChevronRight'];

  return <Svg width={size} height={size} fill={color} {...rest} />;
};

export default Icon;
