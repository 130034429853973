const plugin = require('tailwindcss/plugin');

module.exports = {
  presets: [require('@kcd/feather/tailwind.config')],
  // NOTE: not working
  // corePlugins: {
  //   extend: {
  //     fontWeight: true,
  //   },
  // },
  theme: {
    screens: {},
    extend: {
      // FIXME: use defined values in feather
      spacing: {
        0: '0px',
        1: '4px',
        2: '8px',
        3: '12px',
        4: '16px',
        5: '20px',
        6: '24px',
        7: '28px',
        8: '32px',
        9: '36px',
        10: '40px',
        11: '44px',
      },
      fontSize: {
        display1: ['24px', { lineHeight: '36px', letterSpacing: '-0.036em' }],
        headline: ['21px', { lineHeight: '32px', letterSpacing: '-0.028em' }],
        title: ['19px', { lineHeight: '32px', letterSpacing: '-0.022em' }],
        subtitle: ['17px', { lineHeight: '24px', letterSpacing: '-0.02em' }],
        paragraph2: ['16px', { lineHeight: '28px', letterSpacing: '-0.024em' }],
        paragraph1: ['14px', { lineHeight: '24px', letterSpacing: '-0.02em' }],
        caption: ['13px', { lineHeight: '20px', letterSpacing: '-0.02em' }],
        small: ['12px', { lineHeight: '16px', letterSpacing: '0em' }],
        numeric: ['28px', { lineHeight: '32px', letterSpacing: '0.02em' }],
      },
      textColor: (theme) => ({
        white: theme('colors.warmGray["000"]'),
        danger: {
          light: theme('colors.red["300"]'),
          regular: theme('colors.red["600"]'),
        },
      }),
      backgroundColor: (theme) => ({
        white: theme('colors.warmGray["000"]'),
        lightGray: theme('colors.warmGray["050"]'),
        gray: theme('colors.warmGray["075"]'),
        black: '#000',
        text: {
          normal: theme('colors.warmGray[900]'),
          description: theme('colors.warmGray[600]'),
          disabled: theme('colors.warmGray[400]'),
          accent: theme('colors.mint[700]'),
        },
      }),
      borderColor: (theme) => ({
        screen: { '03': theme('colors.warmGray[100]') },
      }),
      width: {
        300: '300px',
        350: '350px',
        400: '400px',
      },
      height: {
        300: '300px',
        350: '350px',
        400: '400px',
      },
    },
  },
  variants: {
    extend: {
      opacity: ['disabled'],
      cursor: ['disabled'],
      textColor: [
        'responsive',
        'dark',
        'group-hover',
        'focus-within',
        //'hover',
        'focus',
        'active',
      ],
    },
  },
  plugins: [
    // ...featherConfig.plugins,
    plugin(({ addUtilities }) => {
      // FIXME: backward compatibility
      const textStyle = {
        '.text-sm': {
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
        },
      };
      addUtilities(textStyle);

      const fontWeight = {
        '.font-bold': { fontWeight: 700 },
      };
      addUtilities(fontWeight);
    }),
  ],
};
