import {
  isAppWebView,
  platform,
  isEnoughForTargetAppVersion,
} from './userAgent';

export const APP_FEATURE_MIN_SUPPORT_VERSION = {
  android: {
    'urlScheme/market': '1.1.1',
    'integrations/certificate/hometax': '1.1.1',
    pagesUnderHomeTab: '1.2.9',
    'allowSocialLink/facebook': '1.2.9',
    'allowSocialLink/kakaotalk': '1.2.9',
    'integrations/certificate/any': '1.3.9',
    'integrations/certificate/bankAccounts': '1.3.9',
    'integrations/cardFranchises': '1.3.9',
    richDialog: '1.4.2',
    'integrations/certificate/redirectAfter': '1.4.6',
    openModal: '1.5.1',
    hometaxRealtime: '1.5.4',
    loginWithKakao: '1.5.6',
    openExternalBrowser: '1.5.8',
    sendAccessTokenToModal: '1.5.9',
    androidFileAttachmentWithoutModal: '1.6.1',
    openUserCert: '1.6.1',
    secureKeypad: '1.7.3',
    openbanking: 'dev',
  },
  ios: {
    pagesUnderHomeTab: '1.2.5',
    'allowSocialLink/facebook': '1.2.5',
    'allowSocialLink/kakaotalk': '1.2.5',
    crefiaRealtime: '1.2.8',
    richDialog: '1.3.0',
    'integrations/cardFranchises': '1.3.0',
    openModal: '1.3.5',
    hometaxRealtime: '1.3.8',
    loginWithKakao: '1.3.9',
    openExternalBrowser: '1.4.2',
    openUserCert: '1.5.6',
    secureKeypad: '1.5.9',
    daumPostCode: '1.5.9',
    openbanking: 'dev',
  },
};

type AppFeatureKey =
  | keyof typeof APP_FEATURE_MIN_SUPPORT_VERSION.android
  | keyof typeof APP_FEATURE_MIN_SUPPORT_VERSION.ios;

export default function hasAppFeature(featureKey: AppFeatureKey) {
  if (!isAppWebView()) {
    return false;
  }

  const features = APP_FEATURE_MIN_SUPPORT_VERSION[platform()];

  const targetVersion = features[featureKey] as string | undefined;

  if (!targetVersion) {
    return false;
  }

  return isEnoughForTargetAppVersion(targetVersion);
}
