import React from 'react';

import Box, { BoxProps } from 'src/components/commons/Box/Box';
import NavigationBar, {
  RenderRightProps,
} from 'src/components/commons/NavigationBar/NavigationBar';
import { PageContainer } from './PageLayout.styled';

interface PageLayoutProps {
  title: React.ReactNode;
  prevLink?: string | (() => boolean | unknown);
  bg?: 'bg-gray' | 'bg-white';
  noPadding?: boolean;
  renderNavRight?: React.VFC<RenderRightProps>;
}

const PageLayout: React.FC<PageLayoutProps & BoxProps> = ({
  title,
  prevLink,
  bg = 'bg-white',
  noPadding = false,
  renderNavRight,
  children,
}) => {
  React.useEffect(
    () => {
      const prevTitle = document.title;
      if (title) {
        document.title = `직원관리 - ${title}`;
      }

      return () => {
        document.title = prevTitle;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box className={bg}>
      <NavigationBar
        prevLink={prevLink}
        title={title}
        renderRight={renderNavRight}
      />
      {typeof children === 'function' ? (
        children({ PageContainer })
      ) : (
        <PageContainer p={noPadding ? 0 : 5}>{children}</PageContainer>
      )}
    </Box>
  );
};

export default PageLayout;
