import { isAppWebView, web2app } from '@kcd/app-interface';

type ExternalLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  to: string;
};

const ExternalLink = React.forwardRef<
  HTMLAnchorElement,
  ExternalLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
>(({ to, onClick = () => {}, ...rest }, ref) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (isAppWebView()) {
      web2app.openExternalBrowser({ url: to });
      e.preventDefault();
      return;
    }
    onClick(e);
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      href={to}
      ref={ref}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
      {...rest}
    />
  );
});

export default ExternalLink;
