import React from 'react';
import { navigate } from '@redwoodjs/router';
import { gHistory } from '@redwoodjs/router/dist/history';
import { findLastIndex } from 'lodash';
import useRouteSessionStorage from 'src/hooks/useRouteSessionStorage';
import { useToastOptions } from 'src/hooks/useToast';
import { toast } from '@redwoodjs/web/toast';

function ignoringConsoleWarn(callback) {
  const originalConsoleWarn = console.warn;
  console.warn = () => null;

  try {
    return callback();
  } finally {
    console.warn = originalConsoleWarn;
  }
}

// path normalization

type JumpOptions = {
  navigateOnNotFound: boolean;
  ignoreHash: boolean;
  ignoreSearch: boolean;
};

type NormalizedJumpOptions = Omit<JumpOptions, 'navigateOnNotFound'>;

function buildJumpOptions(options?: Partial<JumpOptions>): JumpOptions {
  return {
    navigateOnNotFound: true,
    ignoreHash: true,
    ignoreSearch: true,
    ...options,
  };
}

function buildNormalizeOptions(options?: Partial<NormalizedJumpOptions>) {
  return {
    ignoreHash: true,
    ignoreSearch: true,
    ...options,
  };
}

function normalizePathname(
  pathname: string,
  options: NormalizedJumpOptions
): string {
  const { ignoreHash, ignoreSearch } = options;

  const uri = new URL(pathname, window.location.href);
  if (ignoreHash) {
    uri.hash = '';
  }
  if (ignoreSearch) {
    uri.search = '';
  }

  return uri.toString();
}

function matchPath(
  pathname: string,
  historyItem: string,
  options?: Partial<NormalizedJumpOptions>
) {
  const normalizeOptions = buildNormalizeOptions(options);
  const normPathname = normalizePathname(pathname, normalizeOptions);
  return normalizePathname(historyItem, normalizeOptions) === normPathname;
}

// context

type ContextType = {
  pushToHistory: (pathname: string) => void;
  popFromHistory: (pathname: string) => void;
  replaceEndOfHistory: (pathname: string) => void;
  jumpBackToHistory: (
    pathname: string,
    options?: Partial<JumpOptions> & {
      toast?: string;
      storeSession?: Record<string, unknown>;
    }
  ) => void;
};

export const RouteHistoryContext = React.createContext<ContextType | null>(
  null
);

export function RouteHistoryProvider({ children }) {
  const routeHistory = React.useRef<string[]>([]);
  const { saveToSession, clearSessionStorage } = useRouteSessionStorage();
  const toastOptions = useToastOptions();

  const pushToHistory = React.useCallback((pathname) => {
    routeHistory.current.push(pathname);
    //console.log( 'push:', `"${pathname}"`, '=>', routeHistory.current.length, routeHistory.current );
  }, []);

  const popFromHistory = React.useCallback(
    (pathname, options?: Partial<JumpOptions>) => {
      const _prevPathname = routeHistory.current.pop();
      //console.log( 'pop:', `"${_prevPathname}"`, '<=', routeHistory.current.length, routeHistory.current );

      if (
        !matchPath(
          pathname,
          routeHistory.current[routeHistory.current.length - 1],
          options
        )
      ) {
        console.error(
          `current path is "${pathname}" but top of history is:`,
          routeHistory.current[routeHistory.current.length - 1]
        );
      }

      // clear session on every nav, either front(push) or back(pop).
      clearSessionStorage();
    },
    [clearSessionStorage]
  );

  const replaceEndOfHistory = React.useCallback((pathname) => {
    //routeHistory.current.push(pathname);
    routeHistory.current[routeHistory.current.length - 1] = pathname;
    //console.log( 'replace:', `"${pathname}"`, '=>', routeHistory.current.length, routeHistory.current);
  }, []);

  const jumpBackToHistoryByDistance = React.useCallback((distance) => {
    if (distance > 0) {
      const index = routeHistory.current.length - distance;

      // clear history behind
      // leave one more, which will be handled in popFromHistory
      //console.log( 'slice away history and leave:', routeHistory.current.slice(0, index + 1));
      routeHistory.current = routeHistory.current.slice(0, index + 1);
      //
      history.go(-distance);
      return true;
    }
  }, []);

  // on initial render
  React.useEffect(
    () => {
      const { pathname } = window.location;
      //console.log( 'mount:', `"${pathname}"`, `[${routeHistory.current.length}]`, routeHistory.current);
      pushToHistory(pathname);
    },
    // eslint-disable-next-line
    []
  );

  // every component pushes its pathname to history on mount
  React.useEffect(
    () => {
      // listen to gHistory
      // we don't know if it is history.back() or history.go(-10),
      // but only the direction.
      const listenerId = gHistory.listen((ev) => {
        const { pathname } = window.location;
        //console.log('-> listen:', 'event:', !!ev, pathname);

        // on pushState
        if (ev === undefined) {
          pushToHistory(pathname);
        }
        // on popState
        else {
          popFromHistory(pathname);
        }
      });

      // remove on unmount
      return () => {
        // TODO: what if listener is already removed?
        ignoringConsoleWarn(() => {
          gHistory.remove(listenerId);
        });
      };
    },
    // eslint-disable-next-line
    []
  );

  // callbacks

  const jumpBackToHistory = React.useCallback(
    (
      pathname: string,
      options?: Partial<
        JumpOptions & {
          toast: string;
          storeSession: Record<string, unknown>;
        }
      >
    ) => {
      const { navigateOnNotFound, ignoreHash, ignoreSearch } =
        buildJumpOptions(options);
      const { storeSession = undefined, toast: toastMessage = undefined } =
        options || {};
      const jumpOptions = { ignoreHash, ignoreSearch };

      // search from history
      const index = findLastIndex(routeHistory.current, (historyItem) =>
        matchPath(pathname, historyItem, jumpOptions)
      );

      // not found
      if (index === -1 && !navigateOnNotFound) {
        console.warn(`pathname "${pathname}" not found.`);
        return;
      }

      // save route session storage
      if (storeSession) {
        Object.entries(storeSession).forEach(([key, value]) => {
          saveToSession(key, value);
        });
      }
      if (toastMessage) {
        toast(toastMessage, toastOptions);
      }

      if (index === -1 && navigateOnNotFound) {
        //console.warn(`pathname "${pathname}" not found. force navigate`);
        navigate(pathname);
      } else {
        //console.log('-> target index:', index);
        // assume distance
        const distance = routeHistory.current.length - index - 1;
        //console.log('-> distance:', distance, [ routeHistory.current.length, index, ]);
        jumpBackToHistoryByDistance(distance);
      }
    },
    [jumpBackToHistoryByDistance, saveToSession, toastOptions]
  );

  //
  const value = {
    pushToHistory,
    popFromHistory,
    replaceEndOfHistory,
    jumpBackToHistory,
  };
  return (
    <RouteHistoryContext.Provider value={value}>
      {children}
    </RouteHistoryContext.Provider>
  );
}

function useRouteHistory(): ContextType {
  const context = React.useContext(RouteHistoryContext);
  if (context === null) {
    throw new Error(
      'useRouteHistory must be used within a RouteHistoryProvider'
    );
  }

  return context;
}

export default useRouteHistory;
