const ToastCheckIcon = () => (
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.608 0.405405C19.0854 -0.135135 18.2814 -0.135135 17.7588 0.405405L6.82412 11.7152L2.24121 7.01663C1.71859 6.47609 0.914573 6.47609 0.39196 7.01663C-0.130653 7.55717 -0.130653 8.38877 0.39196 8.92931L5.8995 14.5842C6.1407 14.8337 6.50251 15 6.82412 15C7.14573 15 7.50754 14.8337 7.74874 14.5842L19.608 2.35967C20.1307 1.81913 20.1307 0.945946 19.608 0.405405Z"
      fill="white"
    />
  </svg>
);

export default ToastCheckIcon;
