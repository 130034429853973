import React from 'react';

type ContextType = {
  saveToSession: (key: string, value: unknown) => void;
  popFromSession: <ValueType>(key: string) => ValueType | undefined;
  clearSessionStorage: () => void;
};

export const RouteSessionStorageContext =
  React.createContext<ContextType | null>(null);

export function RouteSessionStorageProvider({ children }) {
  const sessionStorageRef = React.useRef({});

  const saveToSession = (key, value) => {
    sessionStorageRef.current[key] = value;
  };

  const popFromSession = (key) => {
    const value = sessionStorageRef.current[key];
    delete sessionStorageRef.current[key];
    return value;
  };

  //const removeFromStorage = (key) => {
  //  delete sessionStorageRef.current[key];
  //};

  const clearSessionStorage = () => {
    sessionStorageRef.current = {};
  };

  //const cloneSessionStorage = () => {
  //  return { ...sessionStorageRef.current };
  //};

  return (
    <RouteSessionStorageContext.Provider
      value={{
        saveToSession,
        popFromSession,
        clearSessionStorage,
      }}
    >
      {children}
    </RouteSessionStorageContext.Provider>
  );
}

function useRouteSessionStorage(): ContextType {
  const context = React.useContext(RouteSessionStorageContext);
  if (context === null) {
    throw new Error(
      'useRouteSessionStorage must be used within a RouteSessionStorageProvider'
    );
  }
  return context;
}

export default useRouteSessionStorage;
