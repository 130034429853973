import React from 'react';
import { toast } from '@redwoodjs/web/toast';
import { useTheme } from '@emotion/react';

import ToastCheckIcon from 'src/components/employeeInfo/EmployeeList/EmployeeDeleteToast/ToastCheckIcon';
import Styles from 'src/styles/constants';
//import theme from 'src/styles/theme';
import type { Theme as BirchTheme } from 'src/styles/theme';

export function useToastOptions(style = {}) {
  const theme = useTheme() as BirchTheme;
  const background = theme.textColor.paragraph; // TODO: use semantic colors

  return {
    icon: <ToastCheckIcon />,
    duration: Styles.animation.toast.duration,
    style: {
      padding: '10px',
      color: 'white',
      background,
      ...style,
    },
  };
}

export default function useToast(msg, style = {}) {
  const toastOptions = useToastOptions(style);

  return React.useCallback(() => {
    toast(msg, toastOptions);
  }, [msg, toastOptions]);
}
