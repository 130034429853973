import React from 'react';
import { routes } from '@redwoodjs/router';

import useRouteHistory from 'src/hooks/useRouteHistory';
import SystemIcon from '../SystemIcon';

const PrevLink: React.FC<{ to?: string | (() => boolean | unknown) }> = ({
  to: targetPath = routes.staffManagementHome(),
}) => {
  const { jumpBackToHistory } = useRouteHistory();

  return (
    <button
      type="button"
      aria-label="뒤로가기"
      onClick={() => {
        if (typeof targetPath === 'string') {
          jumpBackToHistory(targetPath);
        } else {
          targetPath();
        }
      }}
      style={{ display: 'block' }}
    >
      <SystemIcon icon="MoveBackOutLine" color="current" />
    </button>
  );
};

export default PrevLink;
