import clsx from 'clsx';

import CheckOutine from '@kcd/feather/dist/icons/ic__system__check__outline.svg';
import ChevronDownOutline from '@kcd/feather/dist/icons/ic__system__chevron_down__outline.svg';
import ChevronRightOutline from '@kcd/feather/dist/icons/ic__system__chevron_right__outline.svg';
import ChevronUpOutline from '@kcd/feather/dist/icons/ic__system__chevron_up__outline.svg';
import DropLeftFilled from '@kcd/feather/dist/icons/ic__system__drop_left__filled.svg';
import DropRightFilled from '@kcd/feather/dist/icons/ic__system__drop_right__filled.svg';
import InfoFilled from '@kcd/feather/dist/icons/ic__system__info__filled.svg';
import MoreOutline from '@kcd/feather/dist/icons/ic__system__more__outline.svg';
import MoveBackOutLine from '@kcd/feather/dist/icons/ic__system__move_back__outline.svg';
import MoveForwardOutline from '@kcd/feather/dist/icons/ic__system__move_forward__outline.svg';
import PlusOutline from '@kcd/feather/dist/icons/ic__system__plus__outline.svg';
import RemoveCircleFilled from '@kcd/feather/dist/icons/ic__system__removecircle__filled.svg';
import ScheduleOutline from '@kcd/feather/dist/icons/ic__system__schedule__outline.svg';

const icons = {
  CheckOutine,
  ChevronDownOutline,
  ChevronRightOutline,
  ChevronUpOutline,
  DropLeftFilled,
  DropRightFilled,
  InfoFilled,
  MoreOutline,
  MoveForwardOutline,
  MoveBackOutLine,
  PlusOutline,
  RemoveCircleFilled,
  ScheduleOutline,
} as const;

export type IconNames = keyof typeof icons;

type FeatherColor =
  | 'normal'
  | 'description'
  | 'disabled'
  | 'icon'
  | 'accent'
  | 'link';

type SystemIconProps = React.SVGProps<SVGSVGElement> & {
  icon: IconNames;
  size?: string | number;
  color?: 'current' | FeatherColor;
};

const SystemIcon: React.FC<SystemIconProps> = (props) => {
  const {
    icon: iconName,
    size = 24,
    color = 'icon',
    className,
    ...rest
  } = props;
  const Svg = icons[iconName];

  return (
    <Svg
      width={size}
      height={size}
      fill="currentColor"
      className={clsx(`text-${color}`, className)}
      {...rest}
    />
  );
};

export default SystemIcon;
