import React from 'react';
import clsx from 'clsx';
import { dropOnethDigit, formatNumber } from 'src/utils/numbers';

const MoneySpan: React.FC<{
  amount: number;
  unit?: string;
  className?: string;
  noSpace?: boolean;
}> = ({ amount, unit = '원', className = '', noSpace = false }) => (
  <>
    <span
      className={clsx(
        noSpace ? 'inline-block' : 'inline-block mx-1',
        className
      )}
      title={`${formatNumber(amount)}원에서 10원 단위 이하 절삭`}
    >
      {formatNumber(dropOnethDigit(amount))}
    </span>
    {unit}
  </>
);

export default MoneySpan;
