import { useParams } from '@redwoodjs/router';
import { useAuth } from '@redwoodjs/auth';

import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage';

export function BusinessIdLimitWrapper({ children }) {
  const { businessId } = useParams();
  const { currentUser } = useAuth();
  // const { pathname, search, hash } = useLocation();

  React.useEffect(
    () => {
      console.log(`>> businessId: ${businessId}`);
      console.log(`>> currentUser: ${JSON.stringify(currentUser, null, 2)}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // FIXME: currentUser 타입에는 businessId 가 없다. businessId 는 int 가 아니라 ID 다.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (currentUser?.businessIds.includes(businessId.toString())) {
    return <>{children}</>;
  }

  console.log(`>> businessId 가 허용되지 않음: ${businessId}`);
  // navigate(routes.notFound());
  return <NotFoundPage />;
}
