import hasAppFeature from 'src/utils/appWebView/hasAppFeature';

export default function alertDialog(
  title: string,
  description: string,
  confirmText = '확인',
  fullMessage = undefined
) {
  const fallback = fullMessage || description || title;
  const message = hasAppFeature('richDialog')
    ? JSON.stringify({
        title,
        description,
        confirmText,
      })
    : fallback;

  window.alert(message);
}
