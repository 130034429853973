export default function parseNumericValue(numeric: number | string): number {
  if (typeof numeric === 'number') {
    return numeric;
  }
  if (numeric === '') {
    return 0;
  }

  return parseInt(numeric.replace(/,/g, ''));
}
