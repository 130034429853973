import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from 'src/../tailwind.config';
import styles from './constants';

type ColorNames =
  | 'transparent'
  | 'current'
  | 'mint'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'warmGray'
  | 'primary'
  | 'secondary'
  | 'information'
  | 'success'
  | 'warning'
  | 'danger';

type TextColorNames =
  | 'normal'
  | 'paragraph'
  | 'description'
  | 'hint'
  | 'disabled'
  | 'accent'
  | 'link'
  | 'error'
  | 'white'
  | 'iconIndicator';

type BgColorNames = 'white' | 'lightGray' | 'gray';

export interface TailwindTheme {
  colors: Record<ColorNames, string>;
  textColor: Record<TextColorNames, string>;
  backgroundColor: Record<BgColorNames, string>;
  spacing: Record<number, string>;
  boxShadow: Record<string, string>;
  fontFamily: Record<string, string>;
  fontSize: Record<
    string,
    [string, { lineHeight: string; letterSpacing: string }]
  >;
  width: Record<number, string>;
  height: Record<number, string>;
}

const SPACES = [0, 4, 8, 12, 16, 20, 24, 32, 36, 40, 56, 64, 96, 160] as const;
export type Spaces = typeof SPACES[number];

const theme = {
  ...resolveConfig(tailwindConfig).theme,
  // styled-system
  // following KCD design system guide
  space: SPACES,
  sizes: [0, 4, 8, 12, 16, 20, 24, 32, 36, 40, 56, 64, 96, 160],
  layout: styles,
};

export type Theme = typeof theme;

export default theme;
