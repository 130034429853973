export default {
  navigation: { header: { height: 52 } },
  statusBar: { height: 16 },
  page: { px: 20, py: 20 },
  animation: {
    transitionDuration: 150,
    toast: {
      duration: 4000,
      dismissDelayDuration: 1000, // currently hard-coded and cannot change. see https://github.com/timolins/react-hot-toast/pull/89
    },
  },
} as const;
