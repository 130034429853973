import { identifyUser } from '@kcd/event-tracker';

import type { BirchClient } from './birchClient';

export const birchAuth = (client: BirchClient) => {
  return {
    type: 'custom',
    client,
    login: async (token) => {
      console.log(`>> login: `);
      window.localStorage.setItem('token', token);

      return;
    },
    logout: () => {
      console.log(`>> logout: `);
      window.localStorage.removeItem('currentUser');
      window.localStorage.removeItem('token');

      return;
    },
    getToken: () => client.getToken(),
    getUserMetadata: async () => {
      const userMetadata = await client.getCurrentUser();
      const { account } = userMetadata;

      identifyUser(account.trackingId, {
        publicUserId: account.id,
        // username: account.phoneNumber,
        // name: account.realName ?? account.name,
        createdAt: new Date().toISOString(),
        alias: true,
      });

      return userMetadata;
    },
  };
};
