// This page will be rendered when an error makes it all the way to the top of the
// application without being handled by a Javascript catch statement or React error
// boundary.
//
// You can modify this page as you wish, but it is important to keep things simple to
// avoid the possibility that it will cause its own error. If it does, Redwood will
// still render a generic error page, but your users will prefer something a bit more
// thoughtful. =)
import { isAppWebView, web2app } from '@kcd/app-interface';

import PageLayout from 'src/layouts/PageLayout';
import alertDialog from 'src/utils/alertDialog';

export default () => {
  alertDialog('오류가 발생했습니다. 나중에 다시 시도해 주세요.', '');

  if (isAppWebView()) {
    web2app.closeCurrentModal();
    return;
  }

  return (
    <PageLayout title="직원 관리">
      <main>
        <h1>오류가 발생하였습니다. </h1>
        <p>
          인터넷 연결이 바르지 않거나 서비스가 요청을 처리할 수 없는 상태입니다.
          <br />
          잠시뒤에 다시 시도해주세요.
          {/* <br />
          문제가 지속될 시 고객센터로 문의해 주시면 친절하게 안내해
          드리겠습니다. */}
        </p>
      </main>
    </PageLayout>
  );
};
