import { Toaster } from '@redwoodjs/web/toast';

export function ToastWrapper({ children }) {
  return (
    <>
      <Toaster />
      {children}
    </>
  );
}
