import React from 'react';
import { css } from '@emotion/react';

import styles from 'src/styles/constants';
import Flex from 'src/components/commons/Flex/Flex';
import PrevLink from 'src/components/commons/Header/PrevLink';
import type { Theme } from 'src/styles/theme';
import { Box } from '..';

const linkCss = css`
  width: 15%;
`;

export interface RenderRightProps {
  Container: typeof Flex;
  prevLink?: string | (() => boolean | unknown);
  title: React.ReactNode;
}

interface NavigationBarPros {
  prevLink?: string | (() => boolean | unknown);
  title: React.ReactNode;
  renderRight?: React.VFC<RenderRightProps>;
}

const NavigationBar: React.FC<NavigationBarPros> = ({
  prevLink,
  title,
  renderRight,
}) => {
  const RenderRightContainer = ({ children = null, ...props }) => (
    <Flex css={linkCss} {...props}>
      {children}
    </Flex>
  );
  return (
    <Flex
      as="nav"
      px={4}
      aria-label="navigation bar"
      alignItems="center"
      justifyContent="space-between"
      css={(theme: Theme) => ({
        position: 'sticky',
        top: 0,
        height: styles.navigation.header.height,
        backgroundColor: theme.backgroundColor.white,
        borderBottom: `1px solid ${theme.backgroundColor.gray}`, // TODO: use semantic color name
        zIndex: 10,
        fontWeight: 'bold',
        userSelect: 'none',
      })}
    >
      <Box css={linkCss}>{prevLink && <PrevLink to={prevLink} />}</Box>
      <h2 className="pt-1 line-clamp-1">{title}</h2>
      {renderRight ? (
        renderRight({ Container: RenderRightContainer, prevLink, title })
      ) : (
        <RenderRightContainer />
      )}
    </Flex>
  );
};

export default NavigationBar;
