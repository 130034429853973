import { FatalErrorBoundary as FatalErrorBoundaryBase } from '@redwoodjs/web';
import * as Sentry from '@sentry/browser';

const MAX_RETRY = 1;

interface ChunkLoadError extends Error {
  name: 'ChunkLoadError';
  type?: string;
}

window.onunhandledrejection = (e: PromiseRejectionEvent) => {
  const uri = new URL(window.location.href);
  const retired = parseInt(uri.searchParams.get('retry')) || 0;

  if (
    e.reason?.name === 'ChunkLoadError' &&
    (e.reason as ChunkLoadError).type !== 'timeout' &&
    retired < MAX_RETRY
  ) {
    uri.searchParams.append('retry', (retired + 1).toString());
    window.location.replace(uri.toString());
    e.preventDefault();
    return true;
  }
};

class FatalErrorBoundary extends FatalErrorBoundaryBase {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }
}
export default FatalErrorBoundary;
