import React from 'react';
import styled from '@emotion/styled';
import { compose, space, layout } from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';

// types
import type { SpaceProps, LayoutProps } from 'styled-system';
import type { Interpolation } from '@emotion/react';
import type { Theme } from 'src/styles/theme';

//

type BaseProps = React.RefAttributes<unknown> & {
  as?: React.ElementType;
  css?: Interpolation<Theme>;
};

export type BoxKnownProps = BaseProps & SpaceProps<Theme> & LayoutProps<Theme>;

export type BoxProps = BoxKnownProps &
  Omit<React.HTMLProps<HTMLDivElement>, keyof BoxKnownProps>;

export const Box: React.FC<BoxProps> = styled('div', {
  shouldForwardProp,
})<BoxProps>(
  {},
  (props) => {
    if (!props.css) {
      return props.css;
    }
    return typeof props.css === 'function' ? props.css(props.theme) : props.css;
  },
  compose(space, layout)
);

export default Box;
