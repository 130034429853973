import React from 'react';
import { AuthProvider } from '@redwoodjs/auth';
import { RedwoodProvider } from '@redwoodjs/web';
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo';
import { ThemeProvider } from '@emotion/react';
import * as Sentry from '@sentry/browser';
import { isAppWebView, initializeApp2WebInterface } from '@kcd/app-interface';
import { initializeTrackerInterface } from '@kcd/event-tracker';

import FatalErrorPage from 'src/pages/FatalErrorPage';
import FatalErrorBoundary from './FatalErrorBoundary';
import Routes from 'src/Routes';
import { birchAuth } from 'src/lib/auth';
import { client as birchClient } from 'src/lib/birchClient';

import theme from './styles/theme';

import '@kcd/feather/dist/feather.css';
import './index.css';
import { initializeFontScale } from './utils/appWebView/fontScale';

if (process.env.REDWOOD_ENV_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REDWOOD_ENV_SENTRY_DSN,
    environment: process.env.ENVIRONMENT,
  });
}

if (process.env.NODE_ENV !== 'development') {
  initializeTrackerInterface({
    // sentryConfig: {
    //   dsn:
    //     'https://88d89671ce6449399ac451ffb7c37be3@o42691.ingest.sentry.io/5867512',
    //   allowUrls: [
    //     /^https:\/\/app2\.cashnote\.kr/,
    //     /^https:\/\/app2-staging\.cashnote\.kr/,
    //   ],
    //   environment: process.env.REACT_APP_DEPLOY_ENV,
    //   release: process.env.REACT_APP_GIT_SHA,
    // },
    greenfinchConfig: {
      writeKey: process.env.REDWOOD_ENV_REACT_APP_GREENFINCH_WRITE_KEY,
      serviceName: 'kcd_cashnote',
      debug: process.env.ENVIRONMENT !== 'production',
    },
  });
}

if (isAppWebView()) {
  initializeFontScale();
  initializeApp2WebInterface();
}

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <FatalErrorBoundary page={FatalErrorPage}>
        <RedwoodProvider>
          <AuthProvider client={birchAuth(birchClient)} type="custom">
            <RedwoodApolloProvider
              graphQLClientConfig={{
                connectToDevTools: process.env.NODE_ENV === 'development',
              }}
            >
              <Routes />
            </RedwoodApolloProvider>
          </AuthProvider>
        </RedwoodProvider>
      </FatalErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
